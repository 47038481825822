// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karsh-js": () => import("./../src/pages/karsh.js" /* webpackChunkName: "component---src-pages-karsh-js" */),
  "component---src-pages-marzano-js": () => import("./../src/pages/marzano.js" /* webpackChunkName: "component---src-pages-marzano-js" */),
  "component---src-pages-personal-js": () => import("./../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-reldesign-js": () => import("./../src/pages/reldesign.js" /* webpackChunkName: "component---src-pages-reldesign-js" */),
  "component---src-pages-relsite-js": () => import("./../src/pages/relsite.js" /* webpackChunkName: "component---src-pages-relsite-js" */),
  "component---src-pages-webact-js": () => import("./../src/pages/webact.js" /* webpackChunkName: "component---src-pages-webact-js" */)
}

